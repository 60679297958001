<template>
  <v-container fill-height class="login" :fluid="$vuetify.breakpoint.lgAndDown">
    <v-row justify="center">
      <v-col xs="12" sm="6" md="4">
        <v-card v-if="isLogIn" class="elevation-12">
          <v-card-text>User already logged in.</v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" @click="logout">log out</v-btn>
          </v-card-actions>
        </v-card>
        <v-card v-if="!isLogIn" class="elevation-12">
          <v-card-text>
            <v-form ref="form" v-model="valid" lazy-validation>
              <v-text-field
                v-model="user.email"
                :rules="rules.email"
                prepend-icon="mdi-email"
                label="email"
                required
                ref="mailID"
                outlined
                placeholder=" "
                persistent-placeholder
              />
              <v-text-field
                v-model="user.password"
                :rules="rules.password"
                prepend-icon="mdi-lock"
                label="password"
                type="password"
                required
                @keyup.enter="submit"
                ref="passID"
                outlined
                placeholder=" "
                persistent-placeholder
              />
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn @click="clear">clear</v-btn>
            <v-spacer />
            <v-btn :disabled="!valid" color="primary" @click="submit">submit</v-btn>
          </v-card-actions>
          <v-alert v-if="isErrorLogin" type="error" />
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import user from "../../components/external/modules/user/store/constants";
import path from "../../shared/paths";
import {
  VBtn,
  VCard,
  VCardActions,
  VCardText,
  VCol,
  VContainer,
  VForm,
  VRow,
  VSpacer,
  VTextField,
} from "vuetify/lib";
// import WTextField from "../../components/external/modules/shared/views/WTextField";

export default {
  components: {
    VBtn,
    VCard,
    VCardActions,
    VCardText,
    VCol,
    VContainer,
    VForm,
    VRow,
    VSpacer,
    VTextField,
    // WTextField,
  },

  data() {
    return {
      rules: {
        email: [
          (v) => !!v || "E-mail is required",
          (v) => /\S+@\S+\.\S+/.test(v) || "E-mail must be valid",
        ],
        password: [
          (v) => !!v || "Password is required",
          (v) =>
            (v && v.length >= 7) || "Password must be more than 7 characters",
        ],
      },
      user: {
        email: "",
        password: "",
      },
      valid: true,
      isErrorLogin: false,
      isLogIn: false,
    };
  },

  beforeMount() {
    this.isLogIn = this.$store.getters[user.get.IS_LOGGED_IN](new Date());
  },

  methods: {
    submit() {
      if (this.$refs.form.validate()) {
        this.$store
          .dispatch(user.act.LOGIN, this.user)
          .then(() => {
            this.$store.dispatch(user.act.FETCH_CURRENT_USER, true).then(() => {
              if (this.$route.query.next) {
                // we wanted to reach a specific route, so let's redirect toward it
                this.$router.push(this.$route.query.next);
              } else {
                // we were on the login page, let's open the account view
                this.$router.push(path.ACCOUNT);
              }
            });
          })
          .catch((error) => {
            this.isErrorLogin = true;
            console.log("Error while login: ", error.message, ". Logging out.");
            this.$store.dispatch(user.act.LOGOUT);
          });
      }
    },

    clear() {
      this.$refs.form.reset();
    },

    logout() {
      const self = this;
      this.$store.dispatch(user.act.LOGOUT).finally(() => {
        self.$router.push(path.LOGIN);
        window.location.reload();
      });
    },
  },
};
</script>

<style>
.login {
  background-image: url("../../assets/blue.png");
  background-position: center;
}
</style>
